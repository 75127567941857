import { disableRules, enableRules } from '@/api/pmrules';

const disableRule = {
	id: 'disableRule',
	selectionType: 'multiple',
	label: 'pmrules.actions.disableRules',
	functionality: 'UPDATE_PMRULES',
	checkAvailability: function (instance) {
		return instance && instance.disabled === 0;
	},
	runAction: async function (action, model, registries) {
		var title = this.$puiI18n.t('pmrules.actions.disableRules');

		var body = [];
		for (let i = 0; i < registries.length; i++) {
			body.push({
				pmrulesid: registries[i].pmrulesid
			});
		}

		const data = await disableRules(body);
		if (data.error) {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifyError');
			this.$puiNotify.error(message, title);
		} else {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			this.$puiNotify.success(message, title);
		}
	}
};

const enableRule = {
	id: 'enableRule',
	selectionType: 'multiple', // test single
	label: 'pmrules.actions.enableRules',
	functionality: 'UPDATE_PMRULES',
	checkAvailability: function (instance) {
		return instance && instance.disabled === 1;
	},
	runAction: async function (action, model, registries) {
		var title = this.$puiI18n.t('pmrules.actions.enableRules');

		var body = [];
		for (let i = 0; i < registries.length; i++) {
			body.push({
				pmrulesid: registries[i].pmrulesid
			});
		}

		const data = await enableRules(body);
		if (data.error) {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifyError');
			this.$puiNotify.error(message, title);
		} else {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			this.$puiNotify.success(message, title);
		}
	}
};

export default {
	actions: [disableRule, enableRule]
};
