/* eslint-disable no-unused-vars */
// Aqui haremos las llamadas a todos los endpoints que cuelguen de eventtype
import Pui9HttpRequests from '@Pui9Requests';
import store from '@/store/store';
import { enabledRule, disabledRule } from './databases_API';

export async function disableRules(rules) {
	const controller = '/pmrules/disableRules';

	let data = [];
	let valid = false;
	alert('disable rule: ' + rules[0].pmrulesid + ', ' + rules.length);
	console.log('debug disable rule', rules, store.getters.getRules, store.getters.getTimeunits);

	rules.forEach((rule) => {
		console.log(
			'rule foreach',
			rule,
			store.getters.getRules.find((storedRule) => storedRule.pmrulesid == rule.pmrulesid)
		);
		let selectedRule = store.getters.getRules.find((storedRule) => storedRule.pmrulesid == rule.pmrulesid);
		// delete rule & create it disabled
		if (selectedRule) {
			selectedRule.disabled = 1;
			enabledRule({ id: selectedRule.ruleid })
				.then((solved) => {
					console.info('info update: solved rule ' + selectedRule.acronym, solved);
					valid = true;
				})
				.catch((error) => {
					console.info('info update: error rule ' + selectedRule.acronym, error, error.response, error.response.data);
					error.error = true;
					data = error;
					valid = false;
				});
			console.info('info: pmrules update rule', rules);
		} else {
			let error = new Error('Unable to complete action disable');
			error.error = true;
			data = error;
			valid = false;
		}
	});

	await Pui9HttpRequests.postRequest(
		controller,
		rules,
		(response) => {
			if (response) {
				response.success = true;
				data = response;
			}
		},
		(error) => {
			if (error) {
				error.error = true;
				data = error;
			}
		}
	);

	return data;
}

export async function enableRules(rules) {
	const controller = '/pmrules/enableRules';

	let data = [];
	let valid = false;
	var rulesPromises = [];
	alert('enable rule: ' + rules[0].pmrulesid + ', ' + rules.length);
	console.log('debug enable rule', rules, store.getters.getRules, store.getters.getTimeunits);

	rules.forEach((rule) => {
		console.log(
			'rule foreach',
			rule,
			store.getters.getRules.find((storedRule) => storedRule.pmrulesid == rule.pmrulesid)
		);
		let selectedRule = store.getters.getRules.find((storedRule) => storedRule.pmrulesid == rule.pmrulesid);
		// delete rule & create it disabled
		if (selectedRule) {
			selectedRule.disabled = 0;
			enabledRule({ id: selectedRule.ruleid })
				.then((solved) => {
					console.info('info update: solved rule ' + selectedRule.acronym, solved);
					valid = true;
				})
				.catch((error) => {
					console.info('info update: error rule ' + selectedRule.acronym, error, error.response, error.response.data);
					error.error = true;
					data = error;
					valid = false;
				});
			console.info('info: pmrules update rule', rules);
		} else {
			let error = new Error('Unable to complete action enable');
			error.error = true;
			data = error;
			valid = false;
		}
	});

	await Pui9HttpRequests.postRequest(
		controller,
		rules,
		(response) => {
			if (response) {
				response.success = true;
				data = response;
			}
		},
		(error) => {
			if (error) {
				error.error = true;
				data = error;
			}
		}
	);

	return data;
}
